$('document').ready(function(){

    $('.btn-info-ing').click(function () {
        dataLayerPushFilter($(this));
    });

    $('.btn-info-prep').click(function () {
        dataLayerPushFilter($(this));
    });

    $('.see-recipe-details').click(function () {
        dataLayerPushDetail($(this));
    });

});

function dataLayerPushFilter(eventAction) {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event' : 'gaEvent',
        'eventCategory': 'recipes',
        'eventAction': 'Instructions tab | '+$(eventAction).attr('name'),
        'eventLabel': 'recipes/'+cat+url
    });
}

function dataLayerPushDetail(eventAction) {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event' : 'gaEvent',
        'eventCategory': 'recipes',
        'eventAction': 'view detail',
        'eventLabel': 'recipes/'+$(eventAction).attr('name')
    });
}
